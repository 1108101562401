import { ButtonTopContainer, StyledArticle, StyledButton } from '../../styles';
import { Link, navigate } from 'gatsby';
import React, { useEffect, useRef, useState } from 'react';
import {
	StyledFileContainer,
	StyledTable,
	StyledTableContainer,
	StyledTdId,
} from '../../styles/lista';

import { Layout } from '../../components/Layout';
import Regulamin from '../../static/files/REGULAMIN_obowiazujacy_od_19_04_2021.pdf';
import { StyledH2 } from '../../styles/common';
import axios from '../../config/axios';


import { SEO } from '../../components/SEO';

export const Head = () => (
	<SEO 
		title='Przetargi'
		description='Lista przetargów.'
	/>);


const PrzetargiPage = () => {
	const [list, setList] = useState<any>([]);

	useEffect(() => {
		try {
			(async () => {
				const response = await axios.get('/get_tender_list.php');
				if (response?.status === 200) {
					const data = response.data;
					setList(data);
				}
			})();
		} catch (e) {}
	}, []);

	let przetargiTrwajaceExists = false;
	let przetargiZakonczoneExists = false;
	let sprzedazeTrwajaceExists = false;
	let sprzedazeZakonczoneExists = false;

	for (let i = 0; i < list.length; i++) {
		if (list[i].status === 'przetarg trwający') przetargiTrwajaceExists = true;
		if (list[i].status === 'przetarg zakończony')
			przetargiZakonczoneExists = true;
		if (list[i].status === 'sprzedaż trwająca') sprzedazeTrwajaceExists = true;
		if (list[i].status === 'sprzedaż zakończona')
			sprzedazeZakonczoneExists = true;
	}

	const TableHtml = (props: any) => {
		return (
			<StyledTable>
				<caption>{props.title}</caption>
				<tbody>
					{props.tenderList.map((row: any) => {
						if (row.status === props.status) {
							return (
								<tr key={row.id}>
									<StyledTdId>
										<Link to={`/przetargi/pokaz?id=${row.id}`}>
											{row.id.replace('_', '/')}
										</Link>
									</StyledTdId>
									<td>
										<Link to={`/przetargi/pokaz?id=${row.id}`}>
											{row.title}
										</Link>
									</td>
								</tr>
							);
						} else {
							return null;
						}
					})}
				</tbody>
			</StyledTable>
		);
	};

	return (
		<Layout>
			<StyledArticle>
				<StyledH2 as="h1" color="info">
					Przetargi
				</StyledH2>
				<ButtonTopContainer>
					<StyledButton
						as="a"
						href="https://platformazakupowa.pl/pn/mzk_bydgoszcz"
					>
						Platforma zakupowa
					</StyledButton>
				</ButtonTopContainer>
				<StyledTableContainer>
					{przetargiTrwajaceExists ? (
						<TableHtml
							status="przetarg trwający"
							tenderList={list}
							title="Przetargi trwające"
						/>
					) : null}
				</StyledTableContainer>
				<StyledTableContainer>
					{przetargiZakonczoneExists ? (
						<TableHtml
							status="przetarg zakończony"
							tenderList={list}
							title="Przetargi zakończone"
						/>
					) : null}
				</StyledTableContainer>
				<StyledTableContainer>
					{sprzedazeTrwajaceExists ? (
						<TableHtml
							status="sprzedaż trwająca"
							tenderList={list}
							title="Sprzedaże trwające"
						/>
					) : null}
				</StyledTableContainer>
				<StyledTableContainer>
					{sprzedazeZakonczoneExists ? (
						<TableHtml
							status="sprzedaż zakończona"
							tenderList={list}
							title="Sprzedaże zakończone"
						/>
					) : null}
				</StyledTableContainer>
				<StyledFileContainer>
					<table>
						<tbody>
							<tr>
								<td>
									<a href={Regulamin}>
										<span
											className="file-icon file-icon-md"
											data-type="pdf"
										></span>
									</a>
								</td>

								<td>
									<a href={Regulamin}>
										Regulamin udzielania zamówień o wartości poniżej kwot
										progowych
									</a>
								</td>
							</tr>
						</tbody>
					</table>
				</StyledFileContainer>
			</StyledArticle>
		</Layout>
	);
};

export default PrzetargiPage;
