import styled from 'styled-components';

export const StyledTable = styled.table`
	border-collapse: collapse;
	box-sizing: border-box;
	font-size: 1em;
	margin-bottom: 16px;
	min-width: 60ch;
	width: 100%;
	tr {
		border: 0;
		border-bottom-width: 1px;
		border-color: ${(props) => props.theme.borderColor};
		border-spacing: 0;
		border-style: solid;
	}
	td {
		padding: 8px 0;
		a {
			color: ${(props) => props.theme.darkText};
			text-decoration: none;
			&:hover {
				text-decoration: underline;
			}
		}
	}

	tr:nth-of-type(odd) {
		background-color: #f8f8f8;
	}

	caption {
		border: 0;
		border-bottom-width: 1px;
		border-color: ${(props) => props.theme.borderColor};
		border-spacing: 0;
		border-style: solid;
		color: ${(props) => props.theme.infoColor2};
		font-size: 1.05em;
		margin: 0;
		padding: 0 0 8px 0;
		text-align: left;
	}
`;

export const StyledTableContainer = styled.div`
	box-sizing: border-box;
	margin: 0 16px;
	/* max-width: calc(80ch - 32px); */
	overflow-x: auto;
	position: relative;
	width: calc(100% - 32px);
	/* width: calc(100vw - 48px);
	@media all and (min-width: 940px) {
		width: calc(100vw - 290px - 16px - 64px);
	} */
`;

export const StyledTableFileContainer = styled.div`
	box-sizing: border-box;
	margin: 0;
	overflow-x: auto;
	position: relative;
	width: calc(100% - 32px);
`;

export const StyledTdId = styled.td`
	text-align: center;
	width: 7em;
`;

export const StyledTdButtonDelete = styled.td`
	text-align: center;
	width: 5em;
`;

export const StyledTdButtonPrint = styled.td`
	text-align: center;
	width: 5em;
`;

export const StyledTdCounter = styled.td`
	text-align: center;
	text-align: right;
	width: 4em;
`;

export const StyledFileContainer = styled.div`
	padding: 16px;
	a {
		color: ${(props) => props.theme.darkText};
		text-decoration: none;
		&:hover {
			text-decoration: underline;
		}
	}
`;
